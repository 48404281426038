import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageNotFound from "./Pages/PageNotFound";
import Home from './Pages/Home';
import Login from './Pages/SignIn';
import SignUp from './Pages/SignUp';
import About from './Pages/About';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="/register" element={<SignUp/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/about" element={<About/>} />
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </Router>
  )
}

export default App;
