import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    // Public
                    email: 'Email address',
                    password: 'Password',
                    or: 'or',
                    
                    // Home
                    Home: 'Home',
                    // Sign Up Banner
                    bannerName: "Jia Le's App",
                    bannerContent: "Join us now to unlock what’s coming next.",
                    bannerRegisterBtn: "Register now ",

                    // Page Not Found
                    pageNotFound: "Page Not Found !",
                    sorryMessage: 'Sorry, we couldn’t find the page you’re looking for.',
                    goBackHome: 'Go Back Home',
                    contactSupport: 'Contact Support',

                    // Register
                    register: 'Register',
                    registerTag: 'Register your account',
                    alreadyAMember: 'Already A Member ?',
                    loginNow: 'Login Now',
                    registerWithGoogle: 'Continue with Google',

                    // Login
                    login: 'Login',
                    loginTag: 'Sign in to your account',
                    forgotPassword: 'Forgot Password ?',
                    notAMember: 'Not a member ?',
                    registerNow: 'Register Now',
                    loginWithGoogle: 'Continue with Google',
                }
            },
            zh: {
                translations: {
                    // Public
                    email: '邮箱地址',
                    password: '密码',
                    or: '或',

                    // Home
                    Home: '首页',
                    // Sign Up Banner
                    bannerName: "Jia Le's App",
                    bannerContent: "立即加入我们，来解锁即将到来的内容。",
                    bannerRegisterBtn: "立即注册 ",

                    // Page Not Found
                    pageNotFound: "页面未找到 !",
                    sorryMessage: '抱歉，我们找不到您要查找的页面。',
                    goBackHome: '回到首页',
                    contactSupport: '联系我们',

                    // Register
                    register: '注册',
                    registerTag: '注册您的账号',
                    alreadyAMember: '已经拥有账号了 ?',
                    loginNow: '登录',
                    registerWithGoogle: '使用 Google 注册',

                    // Login
                    login: '登录',
                    loginTag: '登录您的账号',
                    forgotPassword: '忘记密码 ?',
                    notAMember: '没有账号 ?',
                    registerNow: '注册',
                    loginWithGoogle: '使用 Google 登录',
                }
            }
        },
        fallbackLng: "en",
        debug: false,

        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
