import React from 'react';
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";
import '../../src/tw.css';

function PageNotFound() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pageNotFound')}</title>
      </Helmet>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"><Trans i18nKey="pageNotFound">Page not found</Trans></h1>
          <p className="mt-6 text-base leading-7 text-gray-600"><Trans i18nKey="sorryMessage">Sorry, we couldn’t find the page you’re looking for.</Trans></p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <Trans i18nKey="goBackHome">Go back home</Trans>
            </a>
            <a href="/contact" className="text-sm font-semibold text-gray-900">
              <Trans i18nKey='contactSupport'>Contact support</Trans> <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  )
}

export default PageNotFound