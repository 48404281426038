import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import data from '../package.json';
import './tw.css';
import "./i18n";

console.log("Jia Le's Version", data.version)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
