import React, { useState } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";
import { auth } from '../firebase';
import { FcGoogle } from "react-icons/fc";
const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  onAuthStateChanged(auth, (user) => {
    if (user) {
      navigate("/");
    }
  });

  const handleLoginUser = async (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // const user = userCredential.user;
        navigate("/")
        // console.log(user);
      })
      .catch((error) => {
        const errorMessage = error.message.replace('Firebase: ', '');
        // const errorContainer = document.getElementById('error-container');
        // errorContainer.innerText = errorMessage;
        setErrorMessage(errorMessage);
      });
  };

  const handleGoogoleSignIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account"
    });
    try {
      signInWithRedirect(auth, provider)
    }
    catch (err) {
      const errorMessage = err.message;
      console.log(errorMessage);
    }
  }

  const closeError = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Helmet>
        <title>{t('login')}</title>
      </Helmet>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://cdn.jsdelivr.net/gh/JiaLe0709/cdn@master/blog/icon/jiale.png"
            alt="Jia Le"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            <Trans i18nKey="loginTag">Sign in to your account</Trans>
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {errorMessage ? (
            <div id="alert-2" className="flex p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
              <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd"></path></svg>
              <span className="sr-only">Info</span>
              <div className="ml-3 text-sm font-medium">
                {errorMessage}
              </div>
              <button type="button" onClick={closeError} className="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-2" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>
            </div>
          ) : null}
          <br />
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              <Trans i18nKey="email">Email address</Trans>
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                type="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                <Trans i18nKey="password">Password</Trans>
              </label>
              <div className="text-sm">
                <a href="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  <Trans i18nKey="forgotPassword">Forgot password?</Trans>
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                name="password"
                type="password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <br />
          <br />
          <div>
            <button
              type="submit"
              onClick={handleLoginUser}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <Trans i18nKey="login">Sign in</Trans>
            </button>
          </div>
          <br />
          <center>
            <h5 className='text-black font-medium gap-1'><Trans i18nKey="or"> or </Trans></h5>
          </center>
          <br />
          <div className="flex flex-col gap-4">
            <button
              onClick={handleGoogoleSignIn}
              className="text-black bg-white border-black border-2 p-2 w-full font-medium rounded-lg flex align-middle gap-2 "
            >
              <FcGoogle className="text-2xl" />
              <Trans i18nKey="loginWithGoogle">Continue with Google</Trans>
            </button>
          </div>
          <p className="mt-10 text-center text-sm text-gray-500">
            <Trans i18nKey="notAMember">Not a member?</Trans>{' '}
            <a href="/register" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              <Trans i18nKey="registerNow">Register Now</Trans>
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default Login